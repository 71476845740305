/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Header from "../Header"
import Footer from "../Footer"
import { useResizeListener } from "../../hooks/useResizeListener"
import CookieConsent from "react-cookie-consent";
import LiveChat from "../Livechat"
import "./styles.scss"

const MOBILEWIDTH = 980

const Layout = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState()
  useResizeListener(setWindowWidth)

  useEffect(() => {
    setWindowWidth(window?.innerWidth || 1920)
  }, [])

  return (
    <>
      <LiveChat />
      <Header />
      {
        windowWidth <= MOBILEWIDTH && <div id="top-gap" />
      }
      {children}
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Ok"
        buttonStyle={{ backgroundColor: '#961a33', color: 'white' }}>
        We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.
        <span className="privacy-policy-link"><Link to="/privacy-policy/">Privacy Policy</Link></span>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
