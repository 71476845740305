import React from 'react'
import { Link } from 'gatsby'

export const MenuList = () => {
  return (
    <>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-183">
        <Link to="/" activeClassName="active" data-testid="home-link">
          Home
        </Link>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-450">
        <Link to="/history/" activeClassName="active" data-testid="history-link">
          History
        </Link>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-252">
        <Link to="/filtration-studies/" activeClassName="active" data-testid="filtration-studies-link">
          Filtration Studies
        </Link>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-253">
        <Link to="/circuitguard-support-materials/" activeClassName="active" data-testid="documentation-link">
          Documentation
        </Link>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-260">
        <Link to="/about-arc/" activeClassName="active" data-testid="about-arc-link">
          About ARC
        </Link>
      </li>
      <li className="menu-item menu-item-type-post_type menu-item-object-page page_item page-item-209 menu-item-251">
        <Link to="/contact/" activeClassName="active" data-testid="contact-link">
          Contact
        </Link>
      </li>
    </>
  )
}

export default MenuList