import { useEffect, useCallback } from 'react';

export const useResizeListener = (callback) => {
  let f = useCallback((e) => callback(e.target.innerWidth), [callback]);
  useEffect(() => {
    window?.addEventListener('resize', f);

    return () => {
      window?.removeEventListener('resize', f);
    }
  }, [f]);
}