import { Link } from "gatsby"
import React, { useState } from "react"
import MenuList from "./MenuList"
import "./styles.scss"

const Header = () => {
  const [mobileTogglerOpen, setMobileTogglerOpen] = useState(false)

  const mobileMenuClicked = () => {
    setMobileTogglerOpen(!mobileTogglerOpen)
  }

  return (
    <header id="main-header" data-height-onload="118" data-height-loaded="true" data-fixed-height-onload="687.1" data-forceposition="" className="et-fixed-header" data-position="fixed">
      <div id="typenex-acquired" className="divibars-content-inner">
        <a href="https://www.typenex.com" target="_blank" rel="noreferrer">
          <span data-testid="acquisition-message">We are proud to announce that ARC Medical has been acquired by Typenex<span className="registered-trademark">&reg;</span> Medical.</span>
          <span className="typenex-logo">
            <img src="https://res.cloudinary.com/dthskrjhy/image/upload/v1499977187/images/Typenex_Logo.png" alt="Typenex Logo" />
          </span>
        </a>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", height: "95px", padding: "0 30px", margin: "0", width: "100%", maxWidth: "100%", backgroundColor: "#606060" }} className="container clearfix et_menu_container">
        <div className="logo_container">
          <span className="logo_helper"></span>
          <Link to="/">
            <img
              data-testid="logo-url"
              decoding="async"
              src="https://res.cloudinary.com/dthskrjhy/image/upload/v1628624086/Arc_Medical/Arc-Medical-Inc.png"
              alt="Arc Medical"
              id="logo" />
          </Link>
        </div>
        <div id="et-top-navigation" data-height="103" data-fixed-height="61" style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end" }}>
          <nav id="top-menu-nav">
            <ul id="top-menu" className="nav">
              <MenuList />
            </ul>
          </nav>
          <div id="et_mobile_nav_menu">
            <button className="mobile_menu_bar mobile_menu_bar_toggle" onClick={mobileMenuClicked}>
              &#9776;
            </button>
            <ul className={`et_mobile_menu ${mobileTogglerOpen ? 'open' : 'closed'}`}>
              <MenuList />
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
