import React, { useEffect } from "react"

const liveChat = () => {
  let hasTag = false
  let scripts = document.getElementsByTagName("script")
  for (let script of scripts) {
    if (
      script.src ===
      ("https:" === document.location.protocol ? "https://" : "http://") +
      "cdn.livechatinc.com/tracking.js"
    ) {
      hasTag = true
    }
  }
  if (!hasTag) {
    window.__lc = window.__lc || {}
    window.__lc.license = 8686691
      ; (function () {
        var lc = document.createElement("script")
        lc.type = "text/javascript"
        lc.async = true
        lc.src =
          ("https:" === document.location.protocol ? "https://" : "http://") +
          "cdn.livechatinc.com/tracking.js"
        var s = document.getElementsByTagName("script")[0]
        s.parentNode.insertBefore(lc, s)
      })()
  }
}

const LiveChat = () => {
  useEffect(() => liveChat(), [])
  return <></>
}

export default LiveChat
